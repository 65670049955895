import React from 'react'
import { graphql } from 'gatsby'

import Img from 'gatsby-image'

import Layout from 'components/Layout'
import Footer from 'components/Footer'
import Header from 'components/Header'

export default ({ data }) => {
  const headerSources = [
    data.smallHeader.childImageSharp.fluid,
    {
      ...data.mediumHeader.childImageSharp.fluid,
      media: '(min-width: 600px)',
    },
    {
      ...data.largeHeader.childImageSharp.fluid,
      media: '(min-width: 900px)',
    },
  ]

  return (
    <Layout pageTitle='Våra tjänster' siteImage={data.header}>
      <Header
        fluidImage={headerSources}
        imgStyle={{ objectPosition: '50% 80%' }}
      />
      <oma-grid-row>
        <section className='section'>
          <h1 className='heading page__heading'>Våra tjänster</h1>
        </section>
      </oma-grid-row>
      <oma-grid-row class='full_width_image__row'>
        <Img
          className='full_width_image'
          fluid={data.larsWithCows.childImageSharp.fluid}
        />
      </oma-grid-row>
      <oma-grid-row>
        <section className='section'>
          <h2>Lantbrukets djur</h2>
          <p>
            Vi behandlar akuta fall som juverinflammationer,
            kalvningsförlamningar, förlossningskomplikationer, sårskador och
            liknande på ko, får, get och gris. Vi gör även rutinmässiga besök
            som avhorningar, kastrationer och dräktighetsundersökningar.
          </p>
          <p>
            Vi utför utredningar, rådgivning samt upprättar en plan för
            förebyggande åtgärder i din besättning exempelvis vid
            kalvhälsoproblem, juverhälsoproblem eller fertilitetsproblem.
            Kontakta oss så berättar vi gärna mer.
          </p>
          <p>Vi erbjuder även:</p>
          <ul>
            <li>
              Villkorad läkemedelsanvändning - nöt (mjölk- och
              köttproduktion)/får/gris
            </li>
            <li>Smittsäkrad besättning - nöt/gris</li>
            <li>Seminverksamhet (kontroll av djurägarsemin) - nöt</li>
            <li>Hälsopaket mjölk - nöt (mjölkproduktion)</li>
            <li>Kastration - nöt/får/get</li>
            <li>Vasektomi (”teasertjur” och ”teaserbagge”) - nöt/får</li>
            <li>Kejsarsnitt - nöt/får/get</li>
            <li>Vänster och högersidig löpmagsoperation - nöt</li>
            <li>Kontroll av tjurar och baggar inför betäckning - nöt/får</li>
            <li>Vaccination mot ringorm, kalvdiarré, hosta - nöt</li>
          </ul>
          <p>
            Om du har ett ovanligare djurslag och behöver veterinär hjälp och
            rådgivning är du självklart välkommen att ringa också. Vi tar hand
            om alpackor, lamadjur, åsnor och fjäderfän för att bara nämna några
            av våra ovanligare patienter.
          </p>
        </section>
      </oma-grid-row>

      <oma-grid-row class='full_width_image__row'>
        <Img
          className='full_width_image'
          fluid={data.calf3.childImageSharp.fluid}
        />
      </oma-grid-row>

      <oma-grid-row>
        <section className='section'>
          <h2>Häst</h2>
          <p>
            Vi arbetar med häst vid alla akuta händelser exempelvis kolik,
            sårskador, foderstrupsförstoppningar, plötsliga hältor, ögonskador,
            fölningar och sjuka föl. Vi hjälper er med fältmässig utredning av
            långvariga hältor och ridproblematik. Vi tar blodprover för bland
            annat dräktighetsundersökning och hormonella sjukdomar. Om din häst
            har problem med hosta, utslag eller klåda så kan vi hjälpa till med
            utredning och behandling.
          </p>
          <p>
            Vi utför gynekologiska undersökningar med ultraljud inför seminering
            av ston, kontakta oss så berättar vi mer.
          </p>
          <p>
            Det är viktigt att hästens tänder kontrolleras regelbundet och vi
            kan hjälpa dig med en munhåleundersökning och korrigeringar vid
            behov. Vi opererar även bort vargtänder.
          </p>
          <p>Vi erbjuder även:</p>
          <ul>
            <li>
              Vaccination mot influensa, stelkramp, botulism & EHV-1 och 4
            </li>
            <li>Besiktning inför försäljning eller försäkring</li>
            <li>
              Signalementsbeskrivning och chipmärkning för pass till föl eller
              vuxen häst
            </li>
            <li>Tilläggsregistrering/ID-kontroll av importerade hästar</li>
            <li>Lugnande inför klippning eller verkning/skoning</li>
            <li>Stående kastration av hingst</li>
            <li>Dräktighetsundersökning med ultraljud </li>
            <li>Tolkning av träckprovsresultat</li>
            <li>Receptförskrivning</li>
            <li>Exportintyg</li>
          </ul>
        </section>
      </oma-grid-row>
      <oma-grid-row class='full_width_image__row'>
        <Img
          className='full_width_image'
          fluid={data.sheeps.childImageSharp.fluid}
          imgStyle={{ objectPosition: '50% 40%' }}
        />
      </oma-grid-row>
      <oma-grid-row>
        <section className='section'>
          <h2>Sällskapsdjur</h2>
          <p>
            Du är välkommen med din hund, katt eller kanin till vår
            veterinärstation eller så hjälper vi till hemma hos er.
          </p>
          <p>
            Vi utför vaccination av din hund, katt eller kanin. Vi kastrerar
            hon- och hankatter och vi erbjuder paketpris tillsammans med
            vaccination och chipmärkning. När du har en kull med valpar eller
            kattungar hjälper vi till med besiktning, vaccinering och
            chipmärkning. Vi utfärdar även pass för sällskapsdjur och intyg för
            avmaskning inför utlandsresa. Om du behöver ett recept så kontakta
            oss.
          </p>
          <p>
            Vid akuta fall som exempelvis sårskador, bölder och klokapselbrott
            är du välkommen att höra av dig till oss.
          </p>
          <p>
            Vi hjälper dig även när det blivit dags för ditt husdjur att somna
            in. Du väljer om ni vill komma in till veterinärstationen eller så
            hjälper vi till hemma hos er.
          </p>
        </section>
      </oma-grid-row>
      <oma-grid-row class='full_width_image__row'>
        <Img
          className='full_width_image'
          fluid={data.calf2.childImageSharp.fluid}
        />
      </oma-grid-row>
      <oma-grid-row>
        <section className='section'>
          <h2>Officiella uppdrag</h2>
          <p>
            Vi har kompetens utfärdad av Jordbruksverket för att utföra
            officiella uppdrag, såsom intyg vid transport utomlands t ex om din
            häst ska tävla internationellt eller om du vill exportera tjurar
            eller kvigor. Kontakta oss i god tid så berättar vi mer.
          </p>
        </section>
      </oma-grid-row>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query {
    smallHeader: file(relativePath: { eq: "duo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 599, maxHeight: 599, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    mediumHeader: file(relativePath: { eq: "header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900, maxHeight: 600, cropFocus: WEST, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    largeHeader: file(relativePath: { eq: "header.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, maxHeight: 900, cropFocus: NORTH, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    larsWithCows: file(relativePath: { eq: "lars-with-cows.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 500, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    sheep: file(relativePath: { eq: "feeding-sheep.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 500, cropFocus: NORTH, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    sheeps: file(relativePath: { eq: "sheeps.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 500, cropFocus: NORTH, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    calf3: file(relativePath: { eq: "calf3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1050, cropFocus: NORTH, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    calf2: file(relativePath: { eq: "calf2.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 500, cropFocus: NORTH, quality: 90) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`
